import { SkeletonPlaceholder } from '@carbon/react';
import { viewport } from '@placemarkio/geo-viewport';
import turfBbox from '@turf/bbox';
import { featureCollection, point } from '@turf/helpers';
import { Map, Overlay } from 'pigeon-maps';
import { osm } from 'pigeon-maps/providers';

import styles from './ContainerMap.module.scss';
import { ContainerMapMarkerProps } from '../ContainerMapMarker';

type Marker = {
  renderMarker: () => React.ReactElement<ContainerMapMarkerProps>;
  lat: number;
  lng: number;
};

export type ContainerMapProps = {
  loading: boolean;
  markers: Marker[];
  width: number;
  height: number;
  defaultMapCenter?: [number, number];
};

export const ContainerMap: React.FunctionComponent<ContainerMapProps> = ({
  loading,
  markers,
  width,
  height,
  defaultMapCenter = [-84.39, 33.77], // ATL
}) => {
  if (!width || !height) {
    // This could be dynamically set
    return null;
  }

  if (loading) {
    return <SkeletonPlaceholder className={styles.skeleton} />;
  }

  const points = markers.map(({ lat, lng }) => point([lng, lat]));

  if (!points.length) {
    points.push(point(defaultMapCenter));
  }

  const features = featureCollection(points);
  const bounds = turfBbox(features);
  const { center, zoom } = viewport(
    [bounds[0], bounds[1], bounds[2], bounds[3]],
    [width, height],
  );

  return (
    <div className={styles.mapContainer}>
      <Map
        provider={osm}
        center={[center[1], center[0]]}
        zoom={Math.min(zoom, 9)}
        metaWheelZoom
      >
        {markers.map(({ renderMarker, lat, lng }, idx) => {
          return (
            <Overlay
              key={`${idx}-${lat}-${lng}`}
              className={styles.overlay}
              anchor={[lat, lng]}
              offset={[10, 10]}
            >
              {renderMarker()}
            </Overlay>
          );
        })}
      </Map>
    </div>
  );
};
