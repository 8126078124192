import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/counter.css';

import { useState } from 'react';

import { green60 } from '@carbon/colors';
import {
  Close,
  NextOutline,
  PreviousOutline,
  ImageReference,
  Maximize,
  Minimize,
  ZoomIn,
  ZoomOut,
} from '@carbon/icons-react';
import { Link } from '@carbon/react';
import Lightbox from 'yet-another-react-lightbox';
import Counter from 'yet-another-react-lightbox/plugins/counter';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';

export type ViewPhotosProps = {
  label: string;
  photos: string[];
};

export const ViewPhotos: React.FunctionComponent<ViewPhotosProps> = ({
  label,
  photos,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Link
        role="button"
        renderIcon={() => <ImageReference />}
        onClick={() => setOpen(true)}
      >
        ({photos.length}) {label}
      </Link>
      <Lightbox
        plugins={[Zoom, Fullscreen, Counter]}
        zoom={{
          maxZoomPixelRatio: 5,
          scrollToZoom: true,
        }}
        render={{
          iconPrev: () => <PreviousOutline color={green60} size="32" />,
          iconNext: () => <NextOutline color={green60} size="32" />,
          iconClose: () => <Close size="24" />,
          iconEnterFullscreen: /* istanbul ignore next */ () => (
            <Maximize size="24" />
          ),
          iconExitFullscreen: /* istanbul ignore next */ () => (
            <Minimize size="24" />
          ),
          iconZoomIn: () => <ZoomIn size="24" />,
          iconZoomOut: () => <ZoomOut size="24" />,
        }}
        open={open}
        close={() => setOpen(false)}
        slides={photos.map((photo) => ({
          src: photo,
        }))}
      />
    </>
  );
};
