import { CheckmarkOutline } from '@carbon/icons-react';
import { Modal } from '@carbon/react';

import styles from './ConfirmationModal.module.scss';

export type ConfirmationModalProps = {
  heading: React.ReactElement;
  body: React.ReactElement;
  onClose: () => void;
};

export const ConfirmationModal: React.FunctionComponent<
  ConfirmationModalProps
> = ({ heading, body, onClose }) => {
  return (
    <Modal
      open={true}
      passiveModal
      size="xs"
      modalLabel={<CheckmarkOutline size={24} className={styles.checkMark} />}
      modalHeading={heading}
      onRequestClose={onClose}
    >
      {body}
    </Modal>
  );
};
