import { SkeletonPlaceholder, Tile } from '@carbon/react';

import styles from './TileGridTile.module.scss';

export type TileGridTileProps = React.PropsWithChildren<{ loading: boolean }>;

export const TileGridTile: React.FunctionComponent<TileGridTileProps> = ({
  loading,
  children,
}) => {
  if (loading) {
    return (
      <Tile>
        <SkeletonPlaceholder className={styles.skeleton} />
      </Tile>
    );
  }

  return <Tile>{children}</Tile>;
};
