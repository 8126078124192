import { SkeletonText, SkeletonPlaceholder, Link } from '@carbon/react';

import styles from './PageHeader.module.scss';

export type PageHeaderProps = {
  title: string;
  hierarchy?: string[];
  root?: string;
  rootLink?: string;
  loading?: boolean;
  onRootLinkClick?: (rootLink: string) => void;
};

export const PageHeader: React.FunctionComponent<PageHeaderProps> = ({
  title,
  hierarchy,
  root,
  loading,
  rootLink,
  onRootLinkClick,
}) => {
  if (loading) {
    return (
      <div className={styles.pageHeader}>
        <SkeletonText className={styles.skeletonRoot} />
        <SkeletonPlaceholder className={styles.skeletonHeader} />
      </div>
    );
  }

  return (
    <div className={styles.pageHeader}>
      {!!root && !!rootLink && (
        <Link
          href={rootLink}
          onClick={(e: React.SyntheticEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            if (onRootLinkClick) {
              onRootLinkClick(rootLink);
            }
          }}
          className={styles.root}
        >
          {root}
        </Link>
      )}

      {!!root && !rootLink && <p className={styles.root}>{root}</p>}

      <div className={styles.header}>
        <h3>{title}</h3>
        {!!hierarchy && <h4>{hierarchy.join(' | ')}</h4>}
      </div>
    </div>
  );
};
