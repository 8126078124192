import { ProgressBar } from '@carbon/react';

export type FullnessBarProps = {
  maxWeight?: number;
  weight?: number;
  weightLabel: string;
  weightDisplay: string;
  showHelper?: boolean;
};

export const FullnessBar: React.FunctionComponent<FullnessBarProps> = ({
  maxWeight,
  weight,
  weightLabel,
  weightDisplay,
  showHelper = false,
}) => {
  if (!maxWeight) {
    return (
      <ProgressBar
        value={0}
        max={1}
        label="Unknown fullness"
        helperText="Set max weight to estimate fullness"
      />
    );
  }

  const pct = Math.max((weight || 0) / maxWeight, 0.004);

  return (
    <ProgressBar
      value={pct}
      max={1}
      label={`${Math.round(pct * 100)}% full`}
      helperText={
        showHelper
          ? `Max ${weightDisplay} weight: ${maxWeight} ${weightLabel}`
          : undefined
      }
    />
  );
};
