import { SkeletonText } from '@carbon/react';

export type TableCellTextProps = React.PropsWithChildren<{ loading: boolean }>;

export const TableCellText: React.FunctionComponent<TableCellTextProps> = ({
  loading,
  children,
}) => {
  if (loading) {
    return <SkeletonText />;
  }

  return children;
};
