import { createRef, useState } from 'react';

import { Upload } from '@carbon/icons-react';
import { InlineLoading, Link } from '@carbon/react';

import styles from './UploadPhotos.module.scss';

export type UploadPhotosProps = {
  label: string;
  isUploading: boolean;
  photoCount?: number;
  maxPhotoSize?: number;
  onFilesSelected: (files: File[]) => void;
};

export const UploadPhotos: React.FunctionComponent<UploadPhotosProps> = ({
  label,
  photoCount,
  isUploading,
  maxPhotoSize = 5 * 1024 * 1024,
  onFilesSelected,
}) => {
  const [errorOpen, setErrorOpen] = useState(false);
  const fileInputRef = createRef<HTMLInputElement>();

  const handleFileUpload = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    evt.preventDefault();

    const fileList = evt.target.files;

    if (!fileList?.length) {
      return;
    }

    const files = Array.from(fileList);
    const fileNames: string[] = [];
    let hasError = false;

    files.forEach((file) => {
      if (file.size > maxPhotoSize) {
        hasError = true;
      }

      fileNames.push(file.name);
    });

    if (hasError) {
      setErrorOpen(true);
      return;
    }

    setErrorOpen(false);
    onFilesSelected(files);

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <>
      {isUploading ? (
        <InlineLoading
          status="active"
          iconDescription="Uploading photos"
          description="Uploading photos"
        />
      ) : (
        <Link
          role="button"
          renderIcon={() => <Upload />}
          onClick={() => fileInputRef.current?.click()}
        >
          {photoCount !== undefined ? `(${photoCount}) ${label}` : label}
          <input
            data-testid="file-input"
            ref={fileInputRef}
            hidden
            type="file"
            accept="image/*"
            multiple
            onChange={handleFileUpload}
          ></input>
        </Link>
      )}

      {errorOpen && (
        <div className={styles.error}>
          Max photo size is {maxPhotoSize / 1024 / 1024}mb
        </div>
      )}
    </>
  );
};
