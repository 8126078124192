import { useState } from 'react';

import ReactDOM from 'react-dom';

export type ModalStateManagerProps = {
  renderLauncher: React.FunctionComponent<{
    open: boolean;
    setOpen: (open: boolean) => void;
  }>;
  renderModal: React.FunctionComponent<{
    open: boolean;
    setOpen: (open: boolean) => void;
  }>;
  modalKey: string;
};

export const ModalStateManager: React.FunctionComponent<
  ModalStateManagerProps
> = ({
  renderLauncher: LauncherContent,
  renderModal: ModalContent,
  modalKey: key,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      {!open || !ModalContent || typeof document === 'undefined'
        ? null
        : ReactDOM.createPortal(
            <ModalContent open={open} setOpen={setOpen} />,
            document.body,
            key,
          )}
      {LauncherContent && <LauncherContent open={open} setOpen={setOpen} />}
    </>
  );
};
