import { FunctionComponent } from 'react';

import { InlineNotification } from '@carbon/react';

import styles from './ImpersonatingBanner.module.scss';

export type ImpersonatingBannerProps = {
  impersonationEmail?: string;
  onEndImpersonationClick: () => void;
};

export const ImpersonatingBanner: FunctionComponent<
  ImpersonatingBannerProps
> = ({ impersonationEmail, onEndImpersonationClick }) => {
  if (!impersonationEmail) {
    return null;
  }

  return (
    <InlineNotification
      data-testid="inline-notification"
      className={styles.notification}
      role="status"
      kind="warning"
      title={`Impersonating user ${impersonationEmail}`}
      subtitle="Close this notification to end impersonation"
      onCloseButtonClick={onEndImpersonationClick}
    ></InlineNotification>
  );
};
