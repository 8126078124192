import React, { PropsWithChildren, ReactNode } from 'react';

import { User, isSuperUser } from '@wastewizer/authz';

export type SuperUserOnlyProps = PropsWithChildren<{
  user: User;
  onReject: () => ReactNode;
}>;

export const SuperUserOnly: React.FunctionComponent<SuperUserOnlyProps> = ({
  user,
  onReject,
  children,
}) => {
  if (!!user && isSuperUser(user)) {
    return children;
  } else {
    {
      onReject();
    }
  }
};
