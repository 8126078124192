import 'react-modern-drawer/dist/index.css';

import { Close } from '@carbon/icons-react';
import { IconButton, Layer } from '@carbon/react';
import { mediaQueries } from '@wastewizer/ui-constants';
import Drawer from 'react-modern-drawer';
import { useMediaQuery } from 'usehooks-ts';

import styles from './SidePane.module.scss';

export type SidePaneProps = React.PropsWithChildren<{
  open: boolean;
  onClose?: () => void;
}>;

export const SidePane: React.FunctionComponent<SidePaneProps> = ({
  children,
  open,
  onClose,
}) => {
  const isSm = useMediaQuery(mediaQueries.sm);
  const size = isSm ? '100%' : '400px';

  return (
    <Drawer
      open={open}
      onClose={onClose}
      direction="right"
      size={size}
      duration={300}
    >
      <div className={styles.paneBackground}>
        <div className={styles.paneHeader}>
          <IconButton
            enterDelayMs={1000}
            align="right"
            label="Close"
            kind="ghost"
            onClick={onClose}
          >
            <Close size={20} />
          </IconButton>
        </div>
        <div className={styles.paneContent}>
          <Layer>{children}</Layer>
        </div>
      </div>
    </Drawer>
  );
};
